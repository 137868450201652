import { configureStore } from '@reduxjs/toolkit';

// Importing the reducers for each slice of the state
import CustomizerReducer from './customizer/CustomizerSlice';
import authReducer from './auth/AuthSlice';
import contractReducer from './auth/ContractAuthSlice';
import MessageSlice from './chat/MessageSlice';

// Configuring the Redux store by combining the reducers
export const store = configureStore({
  reducer: {
    // State slice for user authentication
    auth: authReducer,
    // State slice for UI customization settings
    customizer: CustomizerReducer,
    // State slice for messages (e.g., chat messages)
    messageSlice: MessageSlice,
    // State slice for contract authentication
    contract: contractReducer,
  },
});

export default store;
