import { createSlice } from "@reduxjs/toolkit";

// Initial state for the layout customizations
const initialState = {
  topbarBg: "",
  customizerSidebar: false,
  isRTL: false,
  isDark: false,
  isMiniSidebar: false,
  sidebarBg: "white",
  isTopbarFixed: true,
  isMobileSidebar: false,
  isSidebarFixed: true,
  isLayoutHorizontal: false,
  isInnerRightPart: false, // Controls visibility of the right part in the three-column layout
};

export const CustomizerSlice = createSlice({
  name: "customizer", // Slice name
  initialState,      // Initial state for the slice
  reducers: {
    // Action to change the topbar background color
    ChangeTopbarColor: (state, action) => {
      state.topbarBg = action.payload;
    },
    
    // Toggle the visibility of the customizer sidebar
    ToggleCustomizer: (state) => {
      state.customizerSidebar = !state.customizerSidebar;
    },

    // Change the layout direction (RTL or LTR)
    ChangeDirection: (state, action) => {
      state.isRTL = action.payload;
    },

    // Toggle dark mode
    ChangeDarkMode: (state, action) => {
      state.isDark = action.payload;
    },

    // Toggle the mini sidebar mode
    ToggleMiniSidebar: (state) => {
      state.isMiniSidebar = !state.isMiniSidebar;
    },

    // Change the sidebar background color
    ChangeSidebarColor: (state, action) => {
      state.sidebarBg = action.payload;
    },

    // Toggle the fixed state of the topbar
    ToggleTopbar: (state, action) => {
      state.isTopbarFixed = action.payload;
    },

    // Toggle the visibility of the mobile sidebar
    ToggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
    },

    // Toggle the fixed state of the sidebar
    FixedSidebar: (state) => {
      state.isSidebarFixed = !state.isSidebarFixed;
    },

    // Toggle between horizontal and vertical layout
    ToggleHorizontal: (state) => {
      state.isLayoutHorizontal = !state.isLayoutHorizontal;
    },

    // Toggle the visibility of the right part in the three-column layout
    ToggleInnerRightPart: (state) => {
      state.isInnerRightPart = !state.isInnerRightPart;
    },
  },
});

// Exporting actions to be used in components
export const {
  ChangeTopbarColor,
  ToggleCustomizer,
  ChangeDirection,
  ChangeDarkMode,
  ToggleMiniSidebar,
  ChangeSidebarColor,
  ToggleTopbar,
  ToggleMobileSidebar,
  FixedSidebar,
  ToggleHorizontal,
  ToggleInnerRightPart,
} = CustomizerSlice.actions;

// Exporting the reducer to be used in the store
export default CustomizerSlice.reducer;
