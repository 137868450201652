import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ContractLoginGuard = ({ children }) => {
  // Accessing the current location and search parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Retrieving the redirectUrl from query parameters
  const redirectUrl = searchParams.get('redirectUrl');

  // Getting the contract state from the Redux store
  const contract = useSelector((state) => state.contract);

  // If the contract user is logged in
  if (contract?.isLoggedin) {
    // If a redirectUrl is present, navigate to it
    if (redirectUrl) {
      return <Navigate to={redirectUrl} />;
    } else {
      // If not, navigate to the contract dashboard
      return <Navigate to="/eSignature/dashboard/contract" />;
    }
  }

  // If not logged in, render children or Outlet (for nested routes)
  return children ? children : <Outlet />;
};

// Defining the expected prop types for the component
ContractLoginGuard.propTypes = {
  children: PropTypes.node,
};

export default ContractLoginGuard;
