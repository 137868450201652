import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ContractAuthGuard = ({ children }) => {
  // Accessing the current location to retrieve pathname and search query
  const location = useLocation();
  const { pathname, search } = location;

  // Retrieving the login status from the Redux store
  const isLoggedin = useSelector((state) => state.contract.isLoggedin);

  // If the user is not logged in, redirect to login page
  if (!isLoggedin) {
    // If the user is attempting to access the /livechat page with search params,
    // redirect them to login with the current pathname and search as a query parameter
    if ((pathname === '/livechat') && search) {
      return <Navigate to={`/eSignature/login?redirectUrl=${pathname}${search}`} />;
    } else {
      // Otherwise, redirect to the login page without extra params
      return <Navigate to="/eSignature/login" />;
    }
  }

  // If the user is logged in, render the children or the Outlet for nested routes
  return children ? children : <Outlet />;
};

// Defining the expected prop types for the component
ContractAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default ContractAuthGuard;
