import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Role = ({ children, roles }) => {
  const userObj = useSelector((state) => state.auth.user);

  // If no roles provided or if the user is a companyadmin, allow access
  if (!roles || typeof roles === "undefined" ) {
    return children;
  }

  // Check if the user's role is in the roles array
  const hasPermission = roles.includes(userObj?.role);

  return !hasPermission ? <Navigate to="/nopermission" /> : children;
};

export default Role;
