import { createSlice } from "@reduxjs/toolkit";

// Initial state structure for the messaging slice
const initialState = {
  chatMessages: [],             // List of chat messages
  property: {},                 // Property details
  chatComments: [],             // List of comments on chats
  userList: [],                 // List of users
  hasMoreChats: true,           // Flag to check if more chats are available
  selectedUsr: {},              // Currently selected user
  usersCount: {},               // Count of users per status (unassigned, bot, etc.)
  activeChatType: "unassinged", // Type of chat (unassigned, bot, etc.)
  listSelectedClose: false,     // Flag to handle chat list close state
  chatCount: 0,                 // Total number of chats
  chatCountData: [],            // Data about chat counts per category/agent
  filter: {}                    // Active filter for chats
};

// Create the messaging slice
const messagingSlice = createSlice({
  name: "messaging",
  initialState,  // Set initial state
  reducers: {
    // Action to save messages to the state
    saveMessages: (state, action) => {
      state.chatMessages = action.payload ? action.payload : [];
    },
    // Action to save comments to the state
    saveComments: (state, action) => {
      state.chatComments = action.payload ? action.payload : [];
    },
    // Action to add messages at the beginning of the chatMessages array
    addMessages: (state, action) => {
      state.chatMessages = action.payload ? [...action.payload, ...state.chatMessages] : [];
    },
    // Action to add comments at the beginning of the chatComments array
    addComments: (state, action) => {
      state.chatComments = action.payload ? [...action.payload, ...state.chatComments] : [];
    },
    // Action to append a new message to the chatMessages array
    appendMessages: (state, action) => {
      state.chatMessages.push(action.payload.chatMessages);
    },
    // Action to add property data to the state
    addProperty: (state, action) => {
      state.property = action.payload;
    },
    // Action to update the last message with a new message ID
    updateMessages: (state, action) => {
      if (!Array.isArray(state.chatMessages)) {
        state.chatMessages = [];
      }
      const lastMessageIndex = state.chatMessages.length - 1;
      if (lastMessageIndex >= 0) {
        state.chatMessages[lastMessageIndex]._id = action.payload;
      }
    },
    // Action to update the count of chat users
    updateCountChatUsers: (state, action) => {
      state.usersCount = action.payload ? action.payload : {};
    },
    // Action to delete a message by its messageId
    deleteMessageByMessageId: (state, action) => {
      state.chatMessages = state.chatMessages.filter((chat) => chat?.messageId !== action?.payload?.messageId);
    },
    // Action to append new comments
    appendComments: (state, action) => {
      state.chatComments.push(action.payload.comments);
    },
    // Action to set the user list
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    // Action to append chats to the user list and manage "hasMoreChats" flag
    appendChats: (state, action) => {
      if (action.payload && action.payload.length) {
        state.userList = [...state.userList, ...action.payload];
      }
      if (!action.payload.length || action.payload.length < 10) {
        state.hasMoreChats = false;
      }
    },
    // Action to mark that chat loading is completed
    setChatsLoadCompleted: (state, action) => {
      state.hasMoreChats = true;
    },
    // Action to append a new user to the user list based on specific conditions
    appendUserList: (state, action) => {
      const payloadAgentIsEmpty = action?.payload?.agent == null;
      const isActiveChatAll = state.activeChatType === "all";
      const isListSelectedCloseFalse = state.listSelectedClose === false;

      const shouldConditionPass = (payloadAgentIsEmpty && !action?.payload?.isBot && (state.activeChatType === "unassinged" || (isActiveChatAll && isListSelectedCloseFalse))) ||
        (payloadAgentIsEmpty && action?.payload?.isBot && (state.activeChatType === "bot" || (isActiveChatAll && !isListSelectedCloseFalse))) ||
        (!payloadAgentIsEmpty && ((isActiveChatAll && isListSelectedCloseFalse) || (state.activeChatType === "me" && isListSelectedCloseFalse && !action?.payload?.isBot) || (state.activeChatType === "bot" && action?.payload?.isBot)));

      if (shouldConditionPass) {
        state.userList.unshift(action.payload);
        state.chatCount += 1;
      }

      if (payloadAgentIsEmpty && !action?.payload?.isBot) {
        state.usersCount.unassigned.total += 1;
      } else {
        if (action?.payload?.isBot) {
          state.usersCount.bot.total += 1;
        } else {
          state.usersCount.me.open += 1;
          state.usersCount.me.total += 1;
        }
      }

      state.usersCount.all.total += 1;
      if (action?.payload?.isBot) {
        state.usersCount.all.close += 1;
      } else {
        state.usersCount.all.open += 1;
      }
    },
    // Action to set the selected user
    setSelectedUser: (state, action) => {
      state.selectedUsr = action.payload || {};
    },
    // Action to mark messages as read and update unread count
    markMessagesAsRead: (state, action) => {
      const { userId, newUnreadCount } = action.payload;
      const user = state.userList.find((u) => u._id === userId);
      if (user) {
        user.unreadMessage = newUnreadCount;
      }
    },
    // Action to update the last message of a user
    updateLastMessageInUserList: (state, action) => {
      const { userId, newLastMessage } = action.payload;
      const userToUpdate = state.userList.find((usr) => usr._id === userId);
      if (userToUpdate) {
        userToUpdate.lastMessage = newLastMessage;
      }
    },
    // Action to clear the chat
    clearChat: (state, action) => {
      state.chatMessages = [];
      state.selectedUsr = {};
    },
    // Action to update the active chat type (unassigned, me, bot, etc.)
    updateChatType: (state, action) => {
      state.activeChatType = action.payload || "";
    },
    // Action to update the tab value for chat list
    updateTabValue: (state, action) => {
      state.listSelectedClose = action.payload || false;
    },
    // Action to update the total chat count
    updateChatCount: (state, action) => {
      state.chatCount = action.payload || 0;
    },
    // Action to add chat count data
    addChatCountData: (state, action) => {
      state.chatCountData = action.payload || [];
    },
    // Action to update the chat count data
    updateChatCountData: (state, action) => {
      if (action.payload) {
        for (let i = 0; i < state.chatCountData.length; i++) {
          if (
            state.chatCountData[i].chatCategory === action.payload.category &&
            state.chatCountData[i].agent === action.payload.agent
          ) {
            state.chatCountData[i].count = state.chatCountData[i].count + 1;
            state.chatCountData[i].new = true;

            const matchedRow = state.chatCountData.splice(i, 1)[0];
            state.chatCountData.unshift(matchedRow);

            break;
          }
        }
      }
    },
    // Action to set the 'new' flag to false in chat count data
     setNewFalse: (state, action) => {
      if (action.payload) {
        for (let i = 0; i < state.chatCountData.length; i++) {
          if (
            state.chatCountData[i].chatCategory === action.payload.category &&
            state.chatCountData[i].agent === action.payload.agent
          ) {
            state.chatCountData[i].new = false;
            break;
          }
        }
      }
    },
    // Action to remove an assigned chat from the user list
    removeAssignedChat: (state, action) => {
      state.userList = state.userList.filter((chat) => chat?._id !== action.payload);
      state.usersCount.unassigned.total -= 1;
    },
    // Action to move unassigned chat to "me"
    moveUnassignedChatToMe: (state, action) => {
      if (action.payload && state.activeChatType === "unassinged") {
        state.userList = state.userList.filter((chat) => chat?._id !== action.payload?.chatId);
        state.usersCount.unassigned.total -= 1;
      }
    },
    // Action to update the active chat filter
    updateChatFilter: (state, action) => {
      state.filter = action.payload || {};
    },
    // Action to remove a missed chat from the user list
    removeMissedChat: (state, action) => {
      if (action.payload) {
        if (state.activeChatType === "all" || state.activeChatType === "unassinged") {
          state.userList = state.userList.filter((chat) => chat?._id !== action.payload?._id);
          state.usersCount.all.close += 1;
        }
      }
    }
  }
});

// Export the actions for use in dispatching
export const {
  saveMessages,
  saveComments,
  addMessages,
  addComments,
  appendMessages,
  addProperty,
  updateMessages,
  updateCountChatUsers,
  deleteMessageByMessageId,
  appendComments,
  setUserList,
  appendChats,
  setChatsLoadCompleted,
  appendUserList,
  setSelectedUser,
  markMessagesAsRead,
  updateLastMessageInUserList,
  clearChat,
  updateChatType,
  updateTabValue,
  updateChatCount,
  addChatCountData,
  updateChatCountData,
  setNewFalse,
  removeAssignedChat,
  moveUnassignedChatToMe,
  updateChatFilter,
  removeMissedChat
} = messagingSlice.actions;

// Export the reducer to be used in the store
export default messagingSlice.reducer;
