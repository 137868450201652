// Import React lazy and a custom Loadable component
import { lazy } from "react";
import Loadable from "src/components/loader/Loadable";

// Import Guards
import AuthGuard from "../utils/auth/AuthGuard";
import LoginGuard from "../utils/auth/LoginGuard";
import Role from "../utils/auth/Roles";
import Permission from "src/utils/auth/Permissions";
import ContractLoginGuard from "src/utils/contractAuth/ContractLoginGuard";
import ContractAuthGuard from "src/utils/contractAuth/ContractAuthGuard";

// Lazy-loaded components
const DemoSetup = Loadable(lazy(() => import("src/views/pages/demo")));
const AccountSetupStepper = Loadable(lazy(() => import("src/views/pages/accountSetup")));
const ContractDetails = Loadable(lazy(() => import("src/views/contract/ContractDetails")));
const ContractLayout = Loadable(lazy(() => import("src/layouts/contractLayout")));
const ContractLogin = Loadable(lazy(() => import("src/views/contract/auth/Login")));
const Templates = Loadable(lazy(() => import("src/views/pages/template")));
const AddTemplate = Loadable(lazy(() => import("src/views/pages/template/AddTemplate")));
const BroadcastNew = Loadable(lazy(() => import("src/views/pages/broadcastNew")));
const AddBroadcastNew = Loadable(lazy(() => import("src/views/pages/broadcastNew/AddBroadcast")));
const BroadcastDetailsNew = Loadable(lazy(() => import("src/views/pages/broadcastNew/broadcastDetail")));
const AgencyDataCount = Loadable(lazy(() => import("src/views/superAdminPages/agencyDataCount")));
const AddProperty = Loadable(lazy(() => import("src/components/pageComponents/property/AddProperty/AddProperty")));
const UpdateListing = Loadable(lazy(() => import("src/components/pageComponents/property/UpdateProperty/UpdateListing")));

/**** Lazy-loaded components ****/
const Subscriptions = Loadable(lazy(() => import("src/views/pages/auth/subscriptions")));
const SubscriptionsList = Loadable(lazy(() => import("src/views/superAdminPages/subscription/Index.js")));
const PaymentConfirmation = Loadable(lazy(() => import("src/views/pages/auth/subscriptions/PaymentConfirmation.jsx")));
const Transactions = Loadable(lazy(() => import("src/views/superAdminPages/transactions/Index")));

/**** Layouts ****/
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
const PublicLayout = Loadable(lazy(() => import("../layouts/PolicyLayout")));
const SettingLayout = Loadable(lazy(() => import("../layouts/SettingLayout")));

/***** Pages *****/
const General = Loadable(lazy(() => import("src/views/pages/dashboards")));

/***** Apps *****/
const LoginHistory = Loadable(lazy(() => import("src/views/superAdminPages/loginHistory")));
const Todo = Loadable(lazy(() => import("src/views/pages/todo/Todo")));
const Pricing = Loadable(lazy(() => import("src/views/pages/pricing")));
const Contacts = Loadable(lazy(() => import("src/views/pages/contacts")));
const PropertyDetail = Loadable(lazy(() => import("src/views/pages/property/PropertyDetail")));
const NewCalendarApp = Loadable(lazy(() => import("src/views/pages/calendar")));
const TicketList = Loadable(lazy(() => import("src/views/pages/ticket/TicketList")));
const TicketDetail = Loadable(lazy(() => import("src/views/pages/ticket/TicketDetail")));
const BranchSettings = Loadable(lazy(() => import("src/views/pages/settings/Branch")));
const ProfileSettings = Loadable(lazy(() => import("src/views/pages/settings/Profile/Profile")));
const SubscriptionSettings = Loadable(lazy(() => import("src/views/pages/settings/Subscription")));
const UsersDetails = Loadable(lazy(() => import("src/views/pages/settings/Users")));
const ConfigurationSettings = Loadable(lazy(() => import("src/views/pages/settings/Configuration")));
const ManageChat = Loadable(lazy(() => import("src/views/superAdminPages/conversations/Index")));
const Users = Loadable(lazy(() => import("src/views/pages/users/Index")));
const CreateBranch = Loadable(lazy(() => import("src/views/pages/users/AddBranch")));
const AgencyAccess = Loadable(lazy(() => import("src/views/superAdminPages/agencyAccess/Index")));
const AgecnyList = Loadable(lazy(() => import("src/views/superAdminPages/agencyList/Index")));
const AgecnyLeadList = Loadable(lazy(() => import("src/views/superAdminPages/agencyLeadList/Index")));
const AgecnyChatList = Loadable(lazy(() => import("src/views/superAdminPages/agencyChatList/Index")));

/***** Auth Pages *****/
const Error = Loadable(lazy(() => import("src/views/pages/auth/Error")));
const LoginFormik = Loadable(lazy(() => import("src/views/pages/auth/LoginFormik")));
const Maintanance = Loadable(lazy(() => import("src/views/pages/auth/Maintanance")));
const RecoverPassword = Loadable(lazy(() => import("src/views/pages/auth/RecoverPassword")));
const RecoverPwd = Loadable(lazy(() => import("src/views/pages/auth/RecoverPwd")));
const TermsAndCondition = Loadable(lazy(() => import("src/views/pages/policy/TermsAndConditions.jsx")));
const PrivacyPolicy = Loadable(lazy(() => import("src/views/pages/policy/PrivacyPolicy.jsx")));
const UnsubscribeEmail = Loadable(lazy(() => import("src/views/pages/unsubscribe")));
const LeadConfirmation = Loadable(lazy(() => import("src/views/pages/leadConfirmation")));
const EmailConfirmation = Loadable(lazy(() => import("src/views/pages/emailConfirmation")));
const LiveChat = Loadable(lazy(() => import("src/views/pages/liveChat")));
const Channels = Loadable(lazy(() => import("src/views/pages/connectChannels/channels")));
const ChatBotBuilder = Loadable(lazy(() => import("src/views/pages/chatbotBuilder")));
const Bots = Loadable(lazy(() => import("src/views/pages/bots")));
const SuperAdminContract = Loadable(lazy(() => import("src/views/contract/superAdminContract")));
const Contract = Loadable(lazy(() => import("src/views/contract")));
const CreateContract = Loadable(lazy(() => import("src/views/contract/createContract")));
const ContractVerification = Loadable(lazy(() => import("src/views/contract/verifyContract")));
const Success = Loadable(lazy(() => import("src/views/contract/success")));
const PaymentFail = Loadable(lazy(() => import("src/views/contract/failed")));
const GPS = Loadable(lazy(() => import("src/views/pages/gps")));
const Property = Loadable(lazy(() => import("src/views/pages/property")));


/*****Routes******/
const ThemeRoutes = [
  // Main routes with authentication
  {
    path: "/",
    element: (<AuthGuard> <FullLayout /> </AuthGuard>),
    children: [
      { path: "", name: "general", exact: true, element: (<Permission permission="Dashboard"> <General /> </Permission>) },
      { path: "dashboard", name: "general", exact: true, element: (<Permission permission="Dashboard"> <General /> </Permission>) },
      { path: "todo", name: "todo", exact: true, element: (<Permission permission="Todo"> <Todo /> </Permission>) },
      { path: "livechat", name: "Live chat", exact: true, element: (<Permission permission="Chat"> <LiveChat /> </Permission>) },
      { path: "contacts", name: "contacts", exact: true, element: (<Permission permission="Contact"> <Contacts /> </Permission>) },
      { path: "calendar", name: "calendar", exact: true, element: (<Permission permission="Calendar"> <NewCalendarApp /> </Permission>) },
      { path: "login-history", name: "login history", exact: true, element: (<Permission permission="LoginHistory"> <LoginHistory /> </Permission>) },
      { path: "agency-access", name: "agency access", exact: true, element: (<Permission permission="AgencyAccess"> <AgencyAccess /> </Permission>) },
      { path: "contract", name: "agency details", exact: true, element: (<Permission permission="AgencyList"> <SuperAdminContract /> </Permission>) },
      { path: "agency-list", name: "agency details", exact: true, element: (<Permission permission="AgencyList"> <AgecnyList /> </Permission>) },
      { path: "agency-data-count", name: "agency Data Count", exact: true, element: (<Permission permission="AgencyLeadList"> <AgencyDataCount /> </Permission>) },
      { path: "agency-lead-list", name: "agency lead list", exact: true, element: (<Permission permission="AgencyLeadList"> <AgecnyLeadList /> </Permission>) },
      { path: "agency-chat-list", name: "agency chat list", exact: true, element: (<Permission permission="AgencyChatList"> <AgecnyChatList /> </Permission>) },
      { path: "users", name: "users", exact: true, element: (<Permission permission="Users"> <Users /> </Permission>) },
      { path: "users/create-branch", name: "users", exact: true, element: (<Permission permission="Users"> <CreateBranch /> </Permission>) },
      { path: "conversations", name: "conversations", exact: true, element: (<Role roles={["superadmin", "companyadmin"]}> <ManageChat /> </Role>) },
      { path: "transactions", name: "transactions", exact: true, element: (<Role roles={["superadmin"]} ><Transactions /> </Role>) },
      { path: "subscription-list", name: "subscriptions", exact: true, element: (<Role roles={["superadmin"]}> <SubscriptionsList /> </Role>) },
      { path: "pricing", name: "pricing", exact: true, element: (<Permission permission="Price"> <Pricing /> </Permission>) },
      { path: "leads", name: "leads", exact: true, element: (<Permission permission="Lead"> <TicketList /> </Permission>) },
      { path: "leads/detail", name: "lead detail", exact: true, element: (<Permission permission="Lead"> <TicketDetail /> </Permission>) },
      { path: "/channels", name: "channels", exact: true, element: (<Permission permission="Channels"> <Channels /> </Permission>) },
      { path: "/property", name: "property", exact: true, element: (<Permission permission="Lead"> <Property /> </Permission>) },
      { path: "/property/AddProperty", name: "property", exact: true, element: (<Permission permission="Lead"> <AddProperty /> </Permission>) },
      { path: "/property/Update-listing", name: "property", exact: true, element: (<Permission permission="Lead"> <UpdateListing /> </Permission>) },
      { path: "/property/propertyDetail/:id", name: "property-detail", exact: true, element: (<Permission permission="Lead"> <PropertyDetail /> </Permission>) },
      { path: "bots", name: "Chat bot", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <Bots /> </Role>) },
      {
        path: "settings", name: "Setting", element: (<SettingLayout />), children: [
          { path: "branch-details", name: "branch details", exact: true, element: (<Role roles={["companyadmin"]}> <BranchSettings /> </Role>) },
          { path: "channels", name: "channel", exact: true, element: (<Role roles={["companyadmin",]}> <Channels /> </Role>) },
          { path: "my-profile", name: "profile", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <ProfileSettings settings={true} /> </Role>) },
          { path: "subscription", name: "subscription", exact: true, element: (<Role roles={["companyadmin", "companymanager"]}> <SubscriptionSettings /> </Role>) },
          { path: "users-details", name: "subscription", exact: true, element: (<Role roles={["companyadmin"]}> <UsersDetails /> </Role>) },
          { path: "configuration", name: "configuration", exact: true, element: (<Role roles={["companyadmin"]}> <ConfigurationSettings /> </Role>) },
        ]
      },
      { path: "chatbot-builder", name: "Chatbot Builder", exact: true, element: (<Permission permission="ChatbotBuilder"> <ChatBotBuilder /> </Permission>) },
      { path: "chatbot/compare", name: "Chatbot", exact: true, element: (<Permission permission="ChatbotBuilder"> <ChatBotBuilder /> </Permission>) },
      { path: "chatbot/restore", name: "Chatbot", exact: true, element: (<Permission permission="ChatbotBuilder"> <ChatBotBuilder /> </Permission>) },
      { path: "setup-demo", name: "Demo Setup", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <DemoSetup /> </Role>) },
      { path: "templates", name: "Template Message", exact: true, element: (<Role roles={["superadmin", "companyadmin"]}> <Templates /> </Role>) },
      { path: "templates/add", name: "Add Template Message", exact: true, element: (<Role roles={["superadmin", "companyadmin"]}> <AddTemplate /> </Role>) },
      { path: "templates/edit/:id", name: "Add Template Message", exact: true, element: (<Role roles={["superadmin", "companyadmin"]}> <AddTemplate /> </Role>) },
      { path: "broadcast", name: "Broadcast", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <BroadcastNew /> </Role>) },
      { path: "broadcast/add", name: "Add Broadcast Message", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <AddBroadcastNew /> </Role>) },
      { path: "broadcast/detail/:id", name: "Broadcast Detail", exact: true, element: (<Role roles={["superadmin", "companyadmin", "companymanager", "agent"]}> <BroadcastDetailsNew /> </Role>) },
    ],
  },

  // Auth routes
  {
    path: "auth",
    element: (<LoginGuard> <BlankLayout /> </LoginGuard>),
    children: [
      { path: "404", element: <Error /> },
      { path: "login", element: <LoginFormik /> },
      { path: "maintanance", element: <Maintanance /> },
      { path: "recoverpwd", element: <RecoverPassword /> },
      { path: "forgotPwd", element: <RecoverPwd /> },
    ],
  },

  // eSignature routes
  {
    path: "eSignature",
    element: (<BlankLayout />),
    children: [
      { path: "login", element: (<ContractLoginGuard> <ContractLogin /> </ContractLoginGuard>) },
      {
        path: "dashboard", element: <ContractAuthGuard> <ContractLayout /> </ContractAuthGuard>, children: [
          { path: "contract", name: "contract", exact: true, element: (<Contract />) },
          { path: "/eSignature/dashboard/create-contract", name: "create-contract", exact: true, element: (<CreateContract />) },
          { path: "/eSignature/dashboard/contract-details/:id", name: "Contract details", exact: true, element: (<ContractDetails />) },
        ]
      },
    ],
  },

  // Miscellaneous routes
  { path: "gps", element: <GPS /> },
  { path: "unsubscribe", element: <UnsubscribeEmail /> },
  { path: "confirm-lead", element: <LeadConfirmation /> },
  { path: "email-verification", element: <EmailConfirmation /> },
  { path: "contract-verification", element: <ContractVerification /> },
  { path: "subscriptions/:plan", element: <Subscriptions /> },
  { path: "return", element: <PaymentConfirmation /> },
  { path: "success", element: <Success /> },
  { path: "failed", element: <PaymentFail /> },

  // Account setup stepper
  {
    path: "account-setup",
    name: "account setup stepper",
    exact: true,
    element: (<AuthGuard> <Permission permission="AccountSetup"> <AccountSetupStepper /> </Permission> </AuthGuard>),
  },

  // Public routes
  {
    path: "policy",
    element: <PublicLayout />,
    children: [
      { path: "terms-of-service", element: <TermsAndCondition /> },
      { path: "privacypolicy", element: <PrivacyPolicy /> },
    ],
  },

  // Catch-all error route
  { path: "*", element: <Error /> },
];


export default ThemeRoutes;
