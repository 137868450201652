import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import Axios from '../axios';
import { setCredentials } from 'src/store/auth/AuthSlice';

const AuthGuard = ({ children }) => {
  // Accessing the current location (pathname and search parameters)
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname, search } = location;

  // Retrieving login status and user information from Redux state
  const isLoggedin = useSelector((state) => state.auth.isLoggedin);
  const userObj = useSelector((state) => state.auth.user);

  // Checking if the contract query parameter is present in the URL
  const contractParam = new URLSearchParams(location.search).get('contract');

  useEffect(() => {
    // If contract parameter exists, attempt to login using contract details
    if (contractParam) {
      const data = { contract: contractParam };

      // Sending a POST request for authentication
      Axios.post(`/auth/login`, data)
        .then((response) => {
          // Set popup expiration time in cookies and store credentials in Redux
          const expirationTime = 82800;
          Cookies.set("popupTime", expirationTime?.toString(), { expires: 86400 });
          dispatch(setCredentials(response?.data));
        })
        .catch((err) => {
          // Handle error if needed (currently not handled)
        });
    }
  }, [contractParam, dispatch]);

  // If the user is not logged in, handle redirection to login page
  if (!isLoggedin) {
    if ((pathname === '/livechat') && search) {
      return <Navigate to={`/auth/login?redirectUrl=${pathname}${search}`} />;
    } else {
      // Redirect to login page with contract parameter if present
      return <Navigate to={`/auth/login${contractParam ? "?isContract=true" : ""}`} />;
    }
  }

  // If contractParam exists, check if account setup is completed
  if (contractParam) {
    if (userObj?.accountSetupFormStep >= 4) {
      return <Navigate to="/dashboard" />;
    } else {
      return <Navigate to="/account-setup" />;
    }
  }

  // If the user is a companyadmin and has not completed account setup, redirect to account setup page
  if (userObj?.role === "companyadmin" && userObj?.accountSetupFormStep < 4 && pathname !== "/account-setup") {
    return <Navigate to="/account-setup" />;
  }

  // If none of the above conditions match, render the children or Outlet for nested routes
  return children ? children : <Outlet />;
};

// Defining the expected prop types for the component
AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
