import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Permissions = ({ children, permission }) => {
  const userObj = useSelector((state) => state.auth.user);
  const userPermissions = userObj?.permissions ?? [];  // Default to an empty array if undefined

  // If no permission is required or the user is a companyadmin, render the children
  if (!permission || typeof permission === "undefined") {
    return children;
  }

  // Check if the user has the required permission
  const hasPermission = userPermissions.includes(permission);

  return !hasPermission ? <Navigate to="/nopermission" /> : children;
};

export default Permissions;
