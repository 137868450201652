import React from "react";
import { Spinner } from "reactstrap";

import "./loader.scss";

const Loader = ({ borderWidth }) => (
  <div className="fallback-spinner">
    <div className="loading">
      <Spinner color="primary" style={borderWidth ? { borderWidth: `${borderWidth}` } : {}} />
    </div>
  </div>
);
export default Loader;
